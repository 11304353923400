import axios from "axios";
import { API_URL } from "./PCloudAPI";

export const UserAPI={
    login:(values)=>{
        return axios.post(API_URL+'/login', values)
    },
    verifyLogin:()=>{
        return axios.get(API_URL+"/verifyUserToken")
    }
    ,getAllCages:(cageId)=>(axios.get(`${API_URL}/getAllCages/${cageId}`))
}