import React from 'react';
import './../Apps.css';
import FileUploadCircles from './FileUploadCircles';


const Biofouling = ({name}) => {
    return (
        <>
        <FileUploadCircles/>
        </>

    ); 
};

export default Biofouling;